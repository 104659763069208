<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t('report_deposit_withdraw.head') }}</h2>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('report_deposit_withdraw.head_list') }} </v-card-title>
        <v-card-actions class="pb-0 d-flex align-start row">
          <span>
            <date-picker-input :label="$t('report_deposit_withdraw.date_from')" ref="startDate" :date="dateFrom"
              @setValue="dateFrom = $event" />
          </span>
          <span class="ml-2">
            <date-picker-input :label="$t('report_deposit_withdraw.date_to')" ref="endDate" :date="dateTo"
              @setValue="dateTo = $event" />
          </span>
          <span class="ml-2" style="display: flex; margin-left: 5px;">
            <div
              style="width: 98px; height: 38px; text-agent: center; background: #e5e5e5; border-radius: 8px; color: #737076; display: flex; justify-content: center; align-items: center;">
              {{ $t('type') }} </div>
            <v-select :items="roles" item-text="label" v-model="valType" outlined dense style="max-width: 110px;" />
          </span>
          <span class="ml-2 mr-2">
            <v-text-field outlined dense hide-details :placeholder="$t('search')" v-model="searchKeyword" />
          </span>
          <v-btn color="primary" class="px-5" @click="searchReport"> {{ $t('search') }} </v-btn>
        </v-card-actions>
        <v-card-actions class="mt-7">
          <v-row>
            <v-col cols="12" xs="12" md="4">
              <v-card color="#F57C00" dark>
                <v-card-title class="justify-center layout text-center">
                  {{ $t('report_deposit_withdraw.block1') }} <br>
                  ({{ dateFrom }} &nbsp;{{ $t('to') }}&nbsp; {{ dateTo }})
                </v-card-title>
                <v-divider />
                <v-card-title class="justify-center layout">{{ Number(total_register).toLocaleString() }} </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" xs="12" md="4">
              <v-card color="#F57C00" dark>
                <v-card-title class="justify-center layout text-center red--text">
                  {{ $t('report_deposit_withdraw.block2') }} <br>
                  ({{ dateFrom }} &nbsp;{{ $t('to') }}&nbsp; {{ dateTo }})
                </v-card-title>
                <v-divider />
                <v-card-title class="justify-center layout">{{ Number(total_deposit).toLocaleString() }} </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" xs="12" md="4">
              <v-card color="#F57C00" dark>
                <v-card-title class="justify-center layout text-center">
                  {{ $t('report_deposit_withdraw.block3') }} <br>
                  ({{ dateFrom }} &nbsp;{{ $t('to') }}&nbsp; {{ dateTo }})
                </v-card-title>
                <v-divider />
                <v-card-title class="justify-center layout"> {{ Number(total_not_deposit).toLocaleString() }}
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-actions class="pt-0">
          <v-data-table mobile-breakpoint="0" style="width: 100%" :headers="headers" :items="itemsData"
            :no-data-text="$t('not_found')" :no-results-text="$t('not_found')" :options.sync="pagination"
            :server-items-length="pagination.totalItems" :items-per-page="pagination.rowsPerPage" :loading="loading"
            :page.sync="pagination.page" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }">
            <template v-slot:[`item.firstAmountDeposit`]="{ item }">
              {{ item.firstAmountDeposit | currency }}
            </template>
            <template v-slot:[`item.secondDepositPercent`]="{ item }">
              {{ `${(parseFloat(item.secondDepositPercent)).toFixed(2)}%` }}
            </template>
            <template v-slot:[`item.registerCount`]="{ item }">
              {{ Number(item.registerCount).toLocaleString() }}
            </template>
            <template v-slot:[`item.notdepositCount`]="{ item }">
              {{ Number(item.notdepositCount).toLocaleString() }}
            </template>
            <template v-slot:[`item.depositCount`]="{ item }">
              {{ Number(item.depositCount).toLocaleString() }}
            </template>
          </v-data-table>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import store from '@/store'
export default {
  components: {
    DatePickerInput,
    SelectInput,
  },

  data() {
    return {
      dateFrom: moment().format('YY-MM-DD'),
      dateTo: moment().format('YY-MM-DD'),
      searchKeyword: null,
      date: null,
      valType: this.$t('report_deposit_withdraw.status1'),
      roles: [this.$t('report_deposit_withdraw.status1'), this.$t('report_deposit_withdraw.status2'), this.$t('report_deposit_withdraw.status3')],
      currentMemberDetail: {},
      headers: [],
      itemsData: [],
      loading: false,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
      ketType: {
        turnover: this.$t('report_deposit_withdraw.turnover'),
        turnwinloss: this.$t('report_deposit_withdraw.turnwinlose'),
      },
      total: 0,
      total_register: 0,
      total_deposit: 0,
      total_not_deposit: 0,
      filterColumn: null,
      newColumn: false
    }
  },
  async created() {
    this.addLogPage()
    this.getWebSetting()
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        if (cont)
          await this.getReport(
            newValue.page,
            newValue.itemsPerPage,
            this.$refs.startDate.value,
            this.$refs.endDate.value,
            this.searchKeyword
          )
      },
      deep: true,
    },
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
          data = {
            ip: res.data.ip,
            name: 'รายงานสรุปฝาก-ถอน',
            url: window.location.href,
            detail: 'รายงานสรุปฝาก-ถอน',
            admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
          }
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async getWebSetting(){
      try {
        let res = await this.$store.dispatch('getSettingFeature')
        this.filterColumn = res;
        this.newColumn
        const filterColumn = this.filterColumn.find(item => item['column'] === "newReportDepositWithdraw")
        if (filterColumn) {
          this.newColumn = filterColumn.value

          if (filterColumn.value) {
            this.headers = [
              {
                text: this.$t('report_deposit_withdraw.header1'),
                value: 'phone',
                width: '100px',
                align: 'center',
                sortable: false,
              },
              {
                text: this.$t('report_deposit_withdraw.header2'),
                value: 'name',
                width: '100px',
                align: 'center',
                sortable: false,
              },
              {
                text: this.$t('report_deposit_withdraw.header3'),
                value: 'registerCount',
                width: '100px',
                align: 'center',
                sortable: false,
              },
              {
                text: this.$t('report_deposit_withdraw.header4'),
                value: 'depositCountText',
                width: '100px',
                align: 'center',
                sortable: false,
              },
              {
                text: this.$t('report_deposit_withdraw.header5'),
                value: 'notdepositCount',
                width: '100px',
                align: 'center',
                sortable: false,
              },
              {
                text: this.$t('report_deposit_withdraw.header6'),
                value: 'secondDepositText',
                width: '100px',
                align: 'center',
                sortable: false,
              },
              {
                text: this.$t('report_deposit_withdraw.header7'),
                value: 'minDeposit300Count',
                width: '100px',
                align: 'center',
                sortable: false,
              },
              {
                text: this.$t('report_deposit_withdraw.header8'),
                value: 'firstAmountDeposit',
                width: '100px',
                align: 'center',
                sortable: false,
              },
            ]
          } else {
            this.headers = [
              {
                text: this.$t('report_deposit_withdraw.header1'),
                value: 'phone',
                width: '100px',
                align: 'center',
                sortable: false,
              },
              {
                text: this.$t('report_deposit_withdraw.header2'),
                value: 'name',
                width: '100px',
                align: 'center',
                sortable: false,
              },
              {
                text: this.$t('report_deposit_withdraw.header3'),
                value: 'registerCount',
                width: '100px',
                align: 'center',
                sortable: false,
              },
              {
                text: this.$t('report_deposit_withdraw.header4'),
                value: 'depositCountText',
                width: '100px',
                align: 'center',
                sortable: false,
              },
              {
                text: this.$t('report_deposit_withdraw.header5'),
                value: 'notdepositCount',
                width: '100px',
                align: 'center',
                sortable: false,
              },
              {
                text: this.$t('report_deposit_withdraw.header8'),
                value: 'firstAmountDeposit',
                width: '100px',
                align: 'center',
                sortable: false,
              },
            ]
          }
        } else {
          this.headers = [
            {
              text: this.$t('report_deposit_withdraw.header1'),
              value: 'phone',
              width: '100px',
              align: 'center',
              sortable: false,
            },
            {
              text: this.$t('report_deposit_withdraw.header2'),
              value: 'name',
              width: '100px',
              align: 'center',
              sortable: false,
            },
            {
              text: this.$t('report_deposit_withdraw.header3'),
              value: 'registerCount',
              width: '100px',
              align: 'center',
              sortable: false,
            },
            {
              text: this.$t('report_deposit_withdraw.header4'),
              value: 'depositCountText',
              width: '100px',
              align: 'center',
              sortable: false,
            },
            {
              text: this.$t('report_deposit_withdraw.header5'),
              value: 'notdepositCount',
              width: '100px',
              align: 'center',
              sortable: false,
            },
            {
              text: this.$t('report_deposit_withdraw.header8'),
              value: 'firstAmountDeposit',
              width: '100px',
              align: 'center',
              sortable: false,
            },
          ]
        }
      } catch(e) {}
    },
    summaryBonus(value) {
      const amount = value.map(item => item.bonus)
      return amount.reduce((previous, current) => previous + current).toFixed(2)
    },
    async searchReport() {
      this.pagination.page = 1
      await this.getReport(
        1,
        this.pagination.itemsPerPage,
        this.$refs.startDate.value,
        this.$refs.endDate.value,
        this.searchKeyword,
      )
    },
    async getReport(page, row, time_from, time_to, search) {
      this.loading = true
      this.itemsData = []
      let convType = 0

      if (this.valType === 'ทั้งหมด') {
        convType = 0
      } else if (this.valType === 'ได้รับยูสเซอร์แล้ว') {
        convType = 1
      } else {
        convType = 2
      }

      this.$store
        .dispatch('getParnerReport', {
          // page: page,
          // rows: row,
          type_member: 'partner',
          ...(!search ? { page } : {}),
          ...(!search ? { rows: row } : {}),
          ...(time_from ? { time_from: this.formatDateYYYY(time_from) } : {}),
          ...(time_to ? { time_to: this.formatDateYYYY(time_to) } : {}),
          ...(search ? { search } : {}),
          type_usered: convType
        })
        .then(result => {
          this.pagination.totalItems = result.count

          result.data.forEach(element => {
            const depositCountText = `${element.depositCount} = ${element.sameDayFirstDeposit} + ${parseInt(element.depositCount) - parseInt(element.sameDayFirstDeposit)}`;
            const secondDepositText = `${element.secondDepositCount}/${element.depositCount} (${(parseFloat(element.secondDepositPercent)).toFixed(2)}%)`;
            const minDeposit300Count = element.conditionDepositCount;

            this.itemsData.push({
              id: element.id,
              phone: element.phone,
              name: element.name,
              registerCount: element.registerCount,
              depositCount: element.depositCount,
              notdepositCount: element.notdepositCount,
              firstAmountDeposit: element.firstAmountDeposit,
              secondDepositCount: element.secondDepositCount,
              secondDepositPercent: element.secondDepositPercent,
              depositCountText: depositCountText,
              secondDepositText: secondDepositText,
              minDeposit300Count: minDeposit300Count
            })
          })
          this.total_register = result.total.total_register
          this.total_deposit = result.total.depositCount
          this.total_not_deposit = result.total.total_not_deposit
          this.loading = false
        })

      // this.$store
      //   .dispatch('getParnerReportBox', {
      //     type_member: 'partner',
      //     ...(!search ? { page } : {}),
      //     ...(!search ? { rows: row } : {}),
      //     ...(time_from ? { time_from: this.formatDateYYYY(time_from) } : {}),
      //     ...(time_to ? { time_to : this.formatDateYYYY(time_to) } : {}),
      //     ...(search ? { search } : {}),
      //   })
      //   .then(result => {
      //     this.total_register = result.total_register
      //     this.total_deposit = result.total_deposit
      //     this.total_not_deposit = result.total_not_deposit
      //     this.loading = false
      //   })
    },
    formatDateYYYY(date) {
      const dateConv = date?.length > 8 ? date.substring(2) : date
      return moment(`20${dateConv}`).format('YYYY-MM-DD')
    },
  },
}
</script>
